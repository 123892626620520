import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./embed/embed.module').then( m => m.EmbedPageModule)
  },
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'verify-account',
    loadChildren: () => import('./verify-account/verify-account.module').then( m => m.VerifyAccountPageModule)
  },
  {
    path: 'session-ended',
    loadChildren: () => import('./session-ended/session-ended.module').then( m => m.SessionEndedPageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'embed',
    canActivate: [AuthGuard],
    loadChildren: () => import('./embed/embed.module').then( m => m.EmbedPageModule)
  },
  {
    path: 'statement',
    canActivate: [AuthGuard],
    loadChildren: () => import('./statement/statement.module').then( m => m.StatementPageModule)
  },
  {
    path: 'transfer',
    canActivate: [AuthGuard],
    loadChildren: () => import('./transfer/transfer.module').then( m => m.TransferPageModule)
  },
  {
    path: 'transfer-method-dialog',
    canActivate: [AuthGuard],
    loadChildren: () => import('./transfer-method-dialog/transfer-method-dialog.module').then( m => m.TransferMethodDialogPageModule)
  },
  {
    path: 'channel-list-dialog',
    canActivate: [AuthGuard],
    loadChildren: () => import('./channel-list-dialog/channel-list-dialog.module').then( m => m.ChannelListDialogPageModule)
  },
  {
    path: 'menu-modal',
    loadChildren: () => import('./menu-modal/menu-modal.module').then( m => m.MenuModalPageModule)
  },
  {
    path: 'support',
    canActivate: [AuthGuard],
    loadChildren: () => import('./support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'home-menu-modal',
    loadChildren: () => import('./home-menu-modal/home-menu-modal.module').then( m => m.HomeMenuModalPageModule)
  },
  {
    path: 'password-prompt-dialog',
    loadChildren: () => import('./password-prompt-dialog/password-prompt-dialog.module').then( m => m.PasswordPromptDialogPageModule)
  },
  {
    path: 'otp-dialog',
    loadChildren: () => import('./otp-dialog/otp-dialog.module').then( m => m.OtpDialogPageModule)
  },
  {
    path: 'payout-dialog',
    canActivate: [AuthGuard],
    loadChildren: () => import('./payout-dialog/payout-dialog.module').then( m => m.PayoutDialogPageModule)
  },
  {
    path: 'password-reset-dialog',
    loadChildren: () => import('./password-reset-dialog/password-reset-dialog.module').then( m => m.PasswordResetDialogPageModule)
  },
  {
    path: 'country-code-selection',
    loadChildren: () => import('./country-code-selection/country-code-selection.module').then( m => m.CountryCodeSelectionPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
