import { Injectable } from '@angular/core';

@Injectable()
export class AppSettingsService {
	constructor() {
	}

	get<T>(key: string): T {
		let v = JSON.parse(localStorage.getItem(key));

		if (v) {
			if (v.expire) {
				let expire = new Date(v.expire);

				if (expire > new Date()) {
					return v.value;
				}
			} else {
				return v.value as T;
			}
		}

		this.remove(key);

		return null;
	}

	set(key: string, value: AppSetting) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	remove(key: string) {
		localStorage.removeItem(key);
	}

	clear() {
		localStorage.clear();
	}
}

export class AppSetting {
	value: any
	expire?: Date
}

export const SettingKey = {
	profile: "profile",
	rememberMe: "rememberMe",
	sessionExpires: "sessionExpires",
	wallet: "wallet",
	consumer: "consumer",
}

export interface ProfileSetting {
	fname: string
	lname: string
	photo?: string
	
}

export interface WalletSetting {
	display: string
	name: string
}