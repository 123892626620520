import { Injectable } from '@angular/core'
import { EnableTrace } from '../app-settings/app-settings.module';

@Injectable()
export class AppLogService {
	constructor() {
	}

	ex(err: any, ...extra): void {
		console.error(err, ...extra);
	}

	write(message: string, ...extra: any[]): void {
		console.log(message, ...extra)
	}

	warn(message: string, ...extra: any[]): void {
		console.warn(message, ...extra);
	}

	trace(message: string, ...extra: any[]): void {
		if (EnableTrace) {
			console.log(message, ...extra);
		}
	}

}
