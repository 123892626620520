import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonInput } from '@ionic/angular';

import { AppLogService } from '../app-log/app-log.service';

@Component({
    selector: 'app-otp-dialog',
    templateUrl: './otp-dialog.page.html',
    styleUrls: ['./otp-dialog.page.scss'],
})
export class OtpDialogPage implements OnInit {

    @ViewChild ('passcodeInput', { static: true }) passcodeInput: IonInput;

    constructor(
        private log: AppLogService,
        private modal: ModalController,
    ) { }

    ngOnInit() {
    }

    async ionViewDidEnter() {
        let input = await this.passcodeInput.getInputElement();

        setTimeout( () => input.focus(), 500);
    }

    passcode: string = "";

    keypress(ev: KeyboardEvent) {
        const regex = /[^\d]/;

        if (regex.test(ev.key)) {
            ev.preventDefault();
        }

        if (this.passcode.length == 5) {
            this.passcode = this.passcode.concat(ev.key);
            (ev.target as any).blur();

            this.save();
        }
    }

    save() {
        this.modal.dismiss({ passcode: this.passcode });
    }

    close() {
        this.modal.dismiss();
    }
}

export const OtpDialogPageCssClass = "dialog-otp-prompt";