import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { environment } from '../../environments/environment';

import { AppSettingsService } from './app-settings.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class AppSettingsModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: AppSettingsModule,
			providers: [AppSettingsService]
		}
	}
}

export class ApiSettings {
	static readonly host: string = environment.api.host; 
	static readonly apiVer: string = environment.api.version; 
	static readonly clientKey: string = environment.api.clientKey; 
	static readonly clientSecret: string = environment.api.clientSecret; 
}

export const EnableTrace = environment.enableTrace; 
export const DefaultAvatarPhoto = environment.defaultAvatarPhoto; 
export const HostDomain = environment.hostDomain; 
export const RecaptchaKey = environment.recaptchaKey; 
export const StripeKey = environment.stripeKey; 
export const YouTubeApiKey = environment.youTubeApiKey; 
export const YouTubeClientId = environment.youTubeClientId; 