import { Injectable, NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, from } from 'rxjs';
import { distinct, flatMap, toArray } from 'rxjs/operators';
import { AppLogService } from '../app-log/app-log.service';

@NgModule()
export class I18nModule {
	private res: any;
	private readonly readyPromise: Promise<void> = new Promise<void>( res => this.res = res );

	constructor(
		private log: AppLogService,
		private translate: TranslateService
		) {
		this.translate.setDefaultLang(defaultLanguage);

		let language = this.getSuitableLanguage(this.translate.getBrowserLang());

		this.log.trace("Browser set to language: " + language);
		this.translate.use(language);

		Settings.systemLanguage = language;

		this.res();
	}

	ready(): Promise<void> {
		return this.readyPromise;
	}

	getTranslations(keys: string[]): Observable<ITranslation[]> {
		return from(keys)
			.pipe( distinct() )
			.pipe( flatMap( key => this.translate.get(key),
				(key: string, translation: string) =>  {
					return {key: key, translation: translation}
				}
			))
			.pipe( toArray() );
	}

	private getSuitableLanguage(language: string) {
		language = language.substring(0, 2).toLowerCase();

		return availableLanguages.some(x => x.code == language) ? language : defaultLanguage;
	}
}

export const availableLanguages = [
	{ code: 'en', name: 'English' },
	{ code: 'es', name: 'Spanish' },
	{ code: 'fr-ca', name: 'French (Canadian)' },
];

export const defaultLanguage = 'en';

export const Settings = {
	systemLanguage: defaultLanguage
};

export interface ITranslation {
	key: string,
	translation: string,
}

@NgModule({
	imports: [TranslateModule],
	exports: [TranslateModule]
})
export class Translations {
	private translations: ITranslation[] = [];

	constructor(private log: AppLogService, private i18n: I18nModule) {
	}

	ready(keys: string[]): Promise<void> {
		return new Promise<void>( res => {
			if (!keys) keys = [];

			if (keys.indexOf(App_Translation_Missing) < 0) {
				keys.push(App_Translation_Missing);
			}

			this.i18n.ready().then( () => {
				this.i18n.getTranslations(keys)
					.subscribe(
						translations => this.translations = [...translations, ...this.translations],
						() => {},
						() => {
							let missing = this.translations.find( t => t.key === App_Translation_Missing ).translation;

							this.translations
								.filter( t => t.key === t.translation)
								.forEach( t => t.translation = missing );

							res();
						}
					);
			})
		});
	}

	get(key: string): string {
		let found = this.translations.find( x => x.key == key );

		if (found) {
			return found.translation;
		} else {
			this.log.warn("no translation for", key);
			return this.translations.find( x => x.key == App_Translation_Missing).translation;
		}
	}
}

const App_Translation_Missing = "App_Translation_Missing";
