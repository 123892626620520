import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class CookieModule {
	// returns the cookie with the given name,
	// or undefined if not found
	get(name: string) {
	  let matches = document.cookie.match(new RegExp(
	    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
	  ));
	  return matches ? decodeURIComponent(matches[1]) : undefined;
	}

	set(name: string, value: string, options: any = {}) {

	  options = {
	    path: '/',
	    // add other defaults here if necessary
	    ...options
	  };

	  if (options.expires instanceof Date) {
	    options.expires = options.expires.toUTCString();
	  }

	  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

	  for (let optionKey in options) {
	    updatedCookie += "; " + optionKey;
	    let optionValue = options[optionKey];
	    if (optionValue !== true) {
	      updatedCookie += "=" + optionValue;
	    }
	  }

	  document.cookie = updatedCookie;
	}

	delete(name: string) {
	  this.set(name, "", {
	    'max-age': -1
	  })
	}
}

export class CookieValue {
	static readonly UserId = "user-id";
	static readonly UserSession = "user-session";
}