import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController, IonInput } from '@ionic/angular';

import { Translations } from '../i18n/i18n.module';

@Component({
    selector: 'app-password-prompt-dialog',
    templateUrl: './password-prompt-dialog.page.html',
    styleUrls: ['./password-prompt-dialog.page.scss'],
})
export class PasswordPromptDialogPage implements OnInit {

    @ViewChild ('passwordInput', { static: true }) passwordInput: IonInput;

    constructor(
        private alert: AlertController,
        private modal: ModalController,
        private translation: Translations,
    ) {
        this.translation.ready([
            Translation.Error_Password_Heading,
            Translation.Error_Password_Message,
        ]);
    }

    ngOnInit() {
    }

    async ionViewDidEnter() {
        let input = await this.passwordInput.getInputElement();

        setTimeout( () => input.focus(), 500);
    }

    password: string;

    async save() {
        if (!this.password || this.password.trim().length == 0) {
            let msg = await this.alert.create({
                header: this.translation.get(Translation.Error_Password_Heading),
                message: this.translation.get(Translation.Error_Password_Message),
            });

            msg.onDidDismiss().then( async () => {
                let input = await this.passwordInput.getInputElement();

                setTimeout( () => input.focus(), 500);
            })

            msg.present();
        } else {
            this.modal.dismiss({password: this.password.trim()});
        }
    }

    close() {
        this.modal.dismiss();
    }
}

export const PasswordPromptDialogPageCssClass = "dialog-password-prompt";

const Translation = {
    Error_Password_Heading: "Dialog_Password_Prompt.Error_Password_Heading",
    Error_Password_Message: "Dialog_Password_Prompt.Error_Password_Message",
}