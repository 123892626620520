import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';

import { ApiSettings } from '../app-settings/app-settings.module';

@Injectable()
export class ApiClientModule {
	private _host: string;
	private _apiVer: string;
	private _clientKey: string;
	private _clientSecret: string;

	constructor(private http: HttpClient) {
		this._host = ApiSettings.host;
		this._apiVer = ApiSettings.apiVer;
		this._clientKey = ApiSettings.clientKey;
		this._clientSecret = ApiSettings.clientSecret;
	}

	authenticate(): Observable<ApiHeader[]> {
		return Observable.create(o => {
			this.post<Authentication>(
				new ApiRequest("/auth"),
				{ key: this._clientKey, secret: this._clientSecret }
			)
			.subscribe(
				data => o.next([{ name: "Authorization", value: data.token } ]),
				err => o.error(err),
				() => o.complete()
			)
		});
	}

	get<T>(req:ApiRequest): Observable<T> {
		return Observable.create(o => {
			this.http
				.get<ApiResponse<T>>(this._uri(req.path), {headers: this._headers(req.headers)})
				.subscribe(
					(resp) => {
						if (!resp || !resp.response) {
							o.error(new Error("Null response"));
						} else if (resp.response.status >= 300) {
							o.error(resp.response);
						} else {
							o.next(resp.data);
						}
					},
					err => {
						if (err.error && err.error.response) {
							o.error(err.error.response)
						} else {
							o.error(err)
						}
					},
					() => o.complete()
				);
		});
	}

	post<T>(req:ApiRequest, body?:any): Observable<T> {
		return Observable.create(o => {
			this.http
				.post<ApiResponse<T>>(this._uri(req.path), body, {headers: this._headers(req.headers)})
				.subscribe(
					(resp) => {
						if (!resp || !resp.response) {
							o.error(new Error("Null response"));
						} else if (resp.response.status >= 300) {
							o.error(resp.response.statusMessage);
						} else {
							o.next(resp.data);
						}
					},
					err => {
						if (err.error && err.error.response) {
							o.error(err.error.response)
						} else {
							o.error(err)
						}
					},
					() => o.complete()
				);
		});
	}

	postFile<T>(req:ApiRequest, body?:any): Observable<T> {
		return Observable.create(o => {
			let form: FormData = new FormData();

			for (let prop in body) {
				form.append(prop, body[prop]);
			}

			this.http.post<ApiResponse<T>>(this._uri(req.path), form, {headers: this._headers(req.headers)})
				.subscribe(
					(resp) => {
						if (!resp || !resp.response) {
							o.error(new Error("Null response"));
						} else if (resp.response.status >= 300) {
							o.error(resp.response.statusMessage);
						} else {
							o.next(resp.data);
						}
					},
					err => {
						if (err.error && err.error.response) {
							o.error(err.error.response)
						} else {
							o.error(err)
						}
					},
					() => o.complete()
				);
		});
	}

	put<T>(req:ApiRequest, body?:any): Observable<T> {
		return Observable.create(o => {
			this.http
				.put<ApiResponse<T>>(this._uri(req.path), body, {headers: this._headers(req.headers)})
				.subscribe(
					(resp) => {
						if (!resp || !resp.response) {
							o.error(new Error("Null response"));
						} else if (resp.response.status >= 300) {
							o.error(resp.response.statusMessage);
						} else {
							o.next(resp.data);
						}
					},
					err => {
						if (err.error && err.error.response) {
							o.error(err.error.response)
						} else {
							o.error(err)
						}
					},
					() => o.complete()
				);
		});
	}

	delete<T>(req:ApiRequest): Observable<T> {
		return Observable.create(o => {
			this.http
				.delete<ApiResponse<T>>(this._uri(req.path), {headers: this._headers(req.headers)})
				.subscribe(
					(resp) => {
						if (!resp || !resp.response) {
							o.error(new Error("Null response"));
						} else if (resp.response.status >= 300) {
							o.error(resp.response.statusMessage);
						} else {
							o.next(resp.data);
						}
					},
					err => {
						if (err.error && err.error.response) {
							o.error(err.error.response)
						} else {
							o.error(err)
						}
					},
					() => o.complete()
				);
		});
	}

	fileUrl(handle: string): string {
		return this._uri(`/file/${handle}`);
	}

	private _uri(path: string): string {
		return `${this._host}/api/${this._apiVer}${path}`;
	}

	private _headers(headers: ApiHeader[]): HttpHeaders {
		if (typeof headers === "undefined") {
			return null;
		}

		let httpHeaders: HttpHeaders = new HttpHeaders();

		headers.forEach( header => {
			httpHeaders = httpHeaders.set(header.name, header.value);
		});

		return httpHeaders;
	}
}

interface ApiStat {
	status: number,
	statusMessage: string,
	responseTime: number
}

interface ApiResponse<T>  {
	(arg: T) : T;

	response: ApiStat
	data: T
}

interface Authentication {
	token: string
}

export interface ApiHeader {
	name: string,
	value: string
}

export class ApiRequest {
	path: string;
	headers: ApiHeader[];

	constructor(path: string) {
		this.path = path;
		this.headers = [];
	}

	addHeaders(headers: ApiHeader[]) {
		if (headers) {
			headers.forEach(x => this.headers.push(x));
		}

		return this;
	}
}