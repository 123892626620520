// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  
    enableTrace: true,
    defaultAvatarPhoto: "/assets/img/avatar.png",
    hostDomain: "staging.hattitude.net",
    recaptchaKey: "6LcuO98UAAAAAJWtI76x_OpH-DyBXvB-O1sFJq1N",
    stripeKey: "pk_test_51GrnLBDXx6cLvgJUuRE0CxQyU1fTm12m0OH6Y5rmpO0iEpgp8W0Ti1XIcAtRpi3Pfm1T4SIdAaAGAeeBugwd168f00ZquZhSAs",
    youTubeApiKey: "AIzaSyDj7FJZktjgoBAWOv3nvDwxLwOhxPSqAhY",
    youTubeClientId: '818534056228-t3js0c97co2v550d1u2r46bmjo8bh31k.apps.googleusercontent.com',  
    
    api: {
	    host: "https://staging-api.hattitude.net",
	    version: "current",
	    clientKey: "hatclientapp",
	    clientSecret: "7089f8f75623e477e840e2ac9f3d231d",
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
