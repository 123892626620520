import { Injectable } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import { from, Observable, of } from 'rxjs';
import { flatMap, switchMap, map } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';

import { ApiClientModule, ApiHeader, ApiRequest } from '../api-client/api-client.module';
import { ClientAuthResponse, WalletResponse  } from '../api-client/interface';
import { AppLogService } from '../app-log/app-log.service';
import { AppSetting, AppSettingsService, SettingKey } from '../app-settings/app-settings.service';
import { DefaultAvatarPhoto } from '../app-settings/app-settings.module';
import { CookieModule, CookieValue } from '../cookie/cookie.module';

import { OtpDialogPage, OtpDialogPageCssClass } from '../otp-dialog/otp-dialog.page';
import { PasswordPromptDialogPage, PasswordPromptDialogPageCssClass } from '../password-prompt-dialog/password-prompt-dialog.page';

@Injectable()
export class UserSessionService {
	constructor(
		private api: ApiClientModule,
		private log: AppLogService,
		private settings: AppSettingsService,
		private cookie: CookieModule,
		private modal: ModalController,
		private router: Router,
	) {
		this.getToken(false)
			.then(
				_ => this.readyResolve(),
				err => this.log.ex("error looking up token", err)
			);
			
		var sessionTimeout: any;

		let resetTimeout = () => {
			clearTimeout(sessionTimeout);
			
			sessionTimeout = setTimeout( () => { 
				this.end();
				this.router.navigate([ '/session-ended' ]);
			}, 1000 * 60 * 15);
		};

		router.events.subscribe( e => {
			if (e instanceof NavigationStart) {
				resetTimeout();
			}
		});

		window.document.onkeypress = () => {
			resetTimeout();
		};
		
		window.document.onmousemove = () => {
			resetTimeout();
		};

		resetTimeout();
	}

	ready(): Promise<any> {
		return this.readyPromise;
	}

	getToken(usePassword: boolean = true): Promise<string> {
		return new Promise( async (resolve, reject) => {
			let token = this.cookie.get(CookieValue.UserSession);
	
			let reAuth = async () => {
				let id = this.cookie.get(CookieValue.UserId);
				let dialog = await this.modal.create({
					component: PasswordPromptDialogPage,
					cssClass: PasswordPromptDialogPageCssClass,
				});
	
				dialog.onDidDismiss().then( async r => {
					let pw = r && r.data ? r.data.password as string : "";
	
					if (pw && pw.length > 0) {
						this.create(id, pw)
							.then( auth => resolve(auth.token))
							.catch( async () => await reAuth() )
					} else {
						reject();
						
						this.end();
					}
				});
				
				await dialog.present();
			}
	
			if (token) {
				let renewAfter = new Date(this.settings.get(SettingKey.sessionExpires) || new Date());
	
				if (renewAfter <= new Date()) {
					this.renewLease(token)
						.subscribe( 
							r => resolve(r),
							async () => await reAuth()
						);
				} else {
					resolve(token);
				}
			} else if (usePassword) {
				await reAuth();
			} else {
				reject();
			}
		});
	}

	create(id: string, secret: string): Promise<AuthResponse> {
		return new Promise<AuthResponse>( (pass, fail) => {
			let form: any = {
				id: id,
				secret: secret,
			};

			let otpPrompt = (): Promise<string> => {
				return new Promise<string>(async res => {
					let dialog = await this.modal.create({
						component: OtpDialogPage,
						cssClass: OtpDialogPageCssClass,
					});
					
					dialog.onDidDismiss().then( r => {
						if (r.data) {
							let pin = r.data.passcode.toString();
							
							res(pin);
						}
					});
					
					await dialog.present();
				});
			};

			from(this.getConsumerHeader())
				.pipe(flatMap( auth => this.api.post<ClientAuthResponse>(new ApiRequest("/client/auth").addHeaders(auth), form) ))
				.subscribe(
					async res => {
						if (res.profile && res.profile.otp) {
							let pin = await otpPrompt();

							if (!pin || pin.length == 0) {
								fail({ status: 400, statusMessage: "OTP Required" });
								return;
							}
							
							if (res.profile.otp == "SMS" && res.token) {
								pin = pin.concat(`:${res.token}`);
							}
						
							try {
								await this.otp(id, secret, pin);
								pass({ token: res.token, });
							} catch (err) {
								fail(err);
							}
						} else {
							await this.authenticate(res);
							pass({ token: res.token });
						}
					},
					
					err => {
						fail(err)
					}
				)
		});
	}

	otp(id: string, secret: string, pin: string): Promise<AuthResponse> {
		return new Promise<AuthResponse>( (pass, fail) => {
			let form: any = { 
				id: id,
				secret: secret,
				pin: pin,
			};
			
			from(this.getConsumerHeader())
				.pipe(flatMap( auth => this.api.post<ClientAuthResponse>(new ApiRequest("/client/otp").addHeaders(auth), form) ))
				.subscribe(
					async res => {
						await this.authenticate(res);
						pass({ token: res.token });
					},
					
					err => {
						fail(err);
					}
				)
		});
	}
	
	secureRequest(req: ApiRequest): Observable<ApiRequest> {
		return from(this.getToken())
			.pipe( switchMap( t => from(this.getConsumerHeader()), (t, h) => { return { token: t, headers: h} } ) )
			.pipe( map( m =>
				req
					.addHeaders(m.headers)
					.addHeaders([{ name: "x-hat-auth", value: m.token }])
			));
	}

	validate(): Promise<boolean> {
		return new Promise<boolean>( res => {
			this.getToken()
				.then( t => {
					if (t) {
						res(true);
					} else {
						this.end();
						res(false);
					}
				},
				
				() => {
					this.end();
					res(false);
				});
		});
	}
	
	end() {
		this.cookie.delete(CookieValue.UserSession);		
		this.settings.clear();
		this.router.navigate(['/sign-in']);
	}

	private readyPromise: Promise<any> = new Promise<any>( res => this.readyResolve = res );
	private readyResolve: any;

	private async getConsumerHeader(): Promise<ApiHeader[]> {
		return new Promise( (pass, fail) => { 
			let h = this.settings.get<ApiHeader[]>(SettingKey.consumer);
			
			if (h) {
				pass(h);
			} else {
				this.api.authenticate()
					.subscribe( 
						res => {
							let exp = new Date();
							
							exp.setMinutes(exp.getMinutes() + 15);
							this.settings.set(SettingKey.consumer, { value: res, expire: exp });
							
							pass(res);
						},
						
						err => {
							this.log.ex("error authenticating consumer", err);
							fail(err);
						}
					);
			}
		});
	}	

	private renewLease(token: string): Observable<string> {
		let req: ApiRequest = new ApiRequest("/client/lease");

		return from(this.getConsumerHeader())
			.pipe(flatMap( auth => {
				auth.push({ name: "x-hat-auth", value: token })

				return this.api.get<ClientAuthResponse>(req.addHeaders(auth));
			} ))
			.pipe(flatMap( res => {
				let tokenExpiresOn = new Date(res.expiresOn);
				let sessionExpiresOn = new Date(res.expiresOn);

				let sessionExpire: AppSetting = {
					value: new Date(sessionExpiresOn.setMinutes(sessionExpiresOn.getMinutes() - 5)),
					expire: tokenExpiresOn,
				};

				let cookieOptions = this.settings.get<boolean>(SettingKey.rememberMe) ? {expires: tokenExpiresOn.toUTCString()} : null;
				
				this.settings.set(SettingKey.sessionExpires, sessionExpire);
				this.cookie.set(CookieValue.UserSession, res.token, cookieOptions);

				return of(res.token)
			}));
	}
	
	private authenticate(auth: ClientAuthResponse): Promise<void> {
		let tokenExpiresOn = new Date(auth.expiresOn);
		let sessionExpiresOn = new Date(auth.expiresOn);
		let fname = auth.fname;
		let lname = auth.lname;
		let photo = DefaultAvatarPhoto;

		if (auth.profile && auth.profile.photo) {
			photo = this.api.fileUrl(auth.profile.photo);
		}
		
		if (auth.profile && auth.profile.display) {
			fname = auth.profile.display.fname;
			lname = auth.profile.display.lname;
		}

		let profile: AppSetting = {
			value: {
				fname: fname,
				lname: lname,
				photo: photo,
			},
			expire: tokenExpiresOn,
		};

		let sessionExpire: AppSetting = {
			value: new Date(sessionExpiresOn.setMinutes(sessionExpiresOn.getMinutes() - 5)),
			expire: tokenExpiresOn,
		};

		let cookieOptions = this.settings.get<boolean>(SettingKey.rememberMe) ? {expires: tokenExpiresOn.toUTCString()} : null;

		this.settings.set(SettingKey.profile, profile);
		this.settings.set(SettingKey.sessionExpires, sessionExpire);
		this.cookie.set(CookieValue.UserSession, auth.token, cookieOptions);
		this.cookie.set(CookieValue.UserId, auth.id);

		return new Promise( (pass, fail) => {
			this.secureRequest(new ApiRequest("/wallet"))
				.pipe( flatMap( req => this.api.get<WalletResponse>(req) ) )
				.subscribe( 
					res => {
						let wallet = {
							display: res.display,
							name: res.name,
						};
						
						this.settings.set(SettingKey.wallet, { value: wallet, expire: tokenExpiresOn });
						pass();
					},
					
					 err => {
					 	if (err.status >= 500) {
					 		fail(err);
					 	} else {
					 		pass();
					 	}
					 }
				);
	
		});
	}
}

export interface AuthResponse {
	token: string
}