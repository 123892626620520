import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserSessionService } from './user-session.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class UserSessionModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: UserSessionModule,
			providers: [UserSessionService]
		}
	}
}
